
.modal-dialog {
    position: relative;
    /* width: auto; */
    margin: var(--bs-modal-margin);
    pointer-events: none;
    display: flex;
    justify-content: center;
    left: calc(50vw - 278px);
}

.modal-content {
    position: relative;
    display: inline-table;
    flex-direction: column;
    width: 812px;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    /* justify-content: center; */
}

.contenedor-imagenes{
    display: flex;
    flex-direction: row;
    gap:10px;
    width: 900px;
    flex-wrap: wrap;
}

.contenedor-imagen{
    width: 280px;
    height: 250px;

    

}

.contenedor-imagen img{
    width: 280px;
    height: 250px;
    object-fit: cover;
}