body{
    background: rgb(67,184,91);
background: #303C8F;
}

h1{
    color:mediumpurple;
}

h2{
    color: #5bb55b;
    margin-top: 20px;
}
.login-contenedor{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    
    
    
}

.login-formulario{
    width: 60vw;
    height: 70vh;
    background-color: white;
    border-radius: 30px;
    padding: 10px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.login-izquierda{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-izquierda h2{
    font-size: 25px;
    font-weight: bold;
    color:#303C8F;
}
.login-izquierda form{
    width: 70%;
}

.texto-login label span{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    color:#303C8F;
    font-size: 14px;
}

.login-izquierda form input, .login-izquierda form input:focus{
    /* border:1px solid #303C8F; */
    border:none;
    
    background-color: #303C8F;
    border-radius: 15px;
    color:white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login-derecha{
    width: 60%;
}

.login-derecha img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.boton-ingreso{
    width: 100%;
}

@media(max-width: 600px){
    .login-contenedor{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        
    }

    .login-izquierda {
        width: 87%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .login-formulario{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .login-formulario .login-derecha{
        display: none;
    }

    .login-formulario .login-derecha img{
      display: none;
    }

    .login-izquierda form{
        width: 100%;
    }
}


@media(max-width:460px){
    .login-izquierda {
        width: 87%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}