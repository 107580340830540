.acidez-titulo h3{
    display: flex;
    flex-direction: row;
    gap: 10px;
    background: #4A5378;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 14px;
    color: white;
}

.contenedor-busqueda{
    display: flex;
    height: 35px;
    flex-direction: row;
    margin-bottom: 50px;
    gap: 20px;
   
}

.contenedor-input{
    width: 70%;
    
}

.cont-informe{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
}

.contenedor-input input{
    border:2px solid #333952;
    border-radius: 20px;
}

input{
    caret-color: red;
    
    
     
}