
.perfil{
    width: 90%;
    padding-left: 10px;
    margin-left: 10px;
    height: fit-content;
    padding-bottom: 10px;
    background: white;
    padding-top: 15px;
    border-radius: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    
}
.container{
    display: block;
}
.perfil-contenedor{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.perfil h5,h2{
    margin-left: 10px;
    font-size: 16px;
    color:#333952;
}

.perfil h5 span{
    font-weight: bold;
    color:#333952;
}

.botonera2{
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width:1200px){
    .perfil{
        width: 93%;
    }
}

@media (max-width:600px){
    .perfil{
        display: none;
    }

    .contenedor .izquierda {
        width: 85vw;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 20px;
        padding-bottom: 32px;
    }
}
